* {
  font-family: 'Mulish', sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  background: #ebebeb;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

body::-webkit-scrollbar-thumb {
  background-color: #cfd8dc;
}

.ant-btn-primary {
  background: #8a6145;
  color: white;
  /* Text Button 1 (SemiBold) */
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 160% */
  letter-spacing: -0.15px;
  text-transform: capitalize;
  &:hover {
    background: #8a6145 !important;
    color: white !important;
  }
  &:disabled {
    opacity: 0.5;
    background: #8a6145 !important;
    color: white !important;
  }
}

.ant-btn-background-ghost {
  border-radius: 6px;
  border: 2px solid #e6e8ec !important;
  background: #fff;
  color: #262221 !important;
  /* Body 2 (SemiBold) */
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
  &:hover {
    background: #fff !important;
    color: #262221 !important;
  }
  &:disabled {
    opacity: 0.5;
    background: #fff !important;
    color: #262221 !important;
  }
}

.btn-algo {
  background: white;
  color: #262221;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.15px;
  text-transform: capitalize;
  &:hover {
    background: white !important;
    color: #262221 !important;
  }
}

.ant-notification-notice {
  color: white;
  padding: 12px 24px !important;
  &.ant-notification-notice-warning {
    background-color: #faad14;
  }
  &.ant-notification-notice-error {
    background-color: #de3d3d;
  }
  &.ant-notification-notice-success {
    background-color: #26ae33;
  }
  .ant-notification-notice-icon {
    display: none;
  }
  .ant-notification-notice-message {
    color: white !important;
    margin-bottom: 0px;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: -0.24px;
    margin-bottom: 0px !important;
    margin-inline-start: 0px !important;
  }
  .ant-notification-notice-close {
    font-size: 16px !important;
    color: white !important;
    top: 14px !important;
  }
  .ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
  }
}

.btn-link-download {
  color: rgb(15 15 15 / 85%);
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
  &:hover {
    color: #0f0f0f !important;
  }
}

.ant-table {
  .ant-table-thead {
    tr {
      th {
        color: #0f0f0f;
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.14px;
      }
    }
  }
}

.ant-pagination {
  li {
    &.ant-pagination-item-active {
      border: none;
      background-color: #1976d2;
      color: white;
      border-radius: 4px;
      a {
        color: white;
      }
    }
  }
}

.ant-modal {
  .ant-modal-content {
    padding: 0;
    .ant-modal-header {
      background: #382b22;
      padding: 16px 24px;
      color: white;
      font-family: Mulish;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.36px;
      margin-bottom: 0;
      .ant-modal-title {
        color: white;
      }
    }
    .ant-modal-body {
      padding: 24px;
      .modal-box {
        .label {
          color: #777e90;
          font-family: Mulish;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px; /* 133.333% */
          letter-spacing: -0.12px;
          text-transform: uppercase;
          margin-bottom: 8px;
        }
        .infos {
          margin-bottom: 14px;
          .info {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            .value {
              color: #777e90;
              font-family: Mulish;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              letter-spacing: -0.12px;
              line-height: 1;
              &.total {
                color: #382b22;
                font-size: 18px;
                font-weight: 800;
                letter-spacing: -0.36px;
                line-height: 1;
              }
            }
          }
        }
      }
    }
    .ant-modal-footer {
      padding: 0px 24px 24px;
    }
  }
}

.farm-footer-no-margin-right {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  .ant-form-item {
    margin-bottom: 0;
  }
}

.loader {
  animation: spin 2s linear infinite;
}

.modal-lottie {
  .ant-modal-content {
    background: transparent;
    box-shadow: none;
  }
}

.badge {
  width: 142px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #e7f7ef;
  color: #0caf60;
  text-align: right;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
  margin: 0 auto;
  &.expired {
    background: #fff0f0;
    color: #de3d3d;
  }
  &.static {
    background: #30419b;
    color: white;
    height: 24px;
    width: 80px;
  }
}

.status {
  &.active {
    color: #0caf60;
  }
  &.expired,
  &.deactivate {
    color: #de3d3d;
  }
  &.yellow {
    color: #eeac04;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media all and (max-width: 991px) {
  .box {
    padding: 48px 15px 0px;
  }
  img {
    max-width: 100% !important;
  }
}

.row-ip {
  display: flex;
  gap: 8px;
  align-items: center;
  .anticon {
    margin-bottom: 24px;
  }
}

.modal-crypto {
  .label {
    color: #42372a;
    font-family: Mulish;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.12px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  .swap {
    position: relative;
    .swap-input-group {
      display: flex;
      border: 2px solid #f1efed;
      margin-bottom: 6px;
      border-radius: 10px;
      input {
        border: none;
        box-shadow: none;
        overflow: hidden;
        color: #382b22;
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.14px;
        height: 40px;
      }
      .currency {
        border-left: 2px solid #f1efed;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        color: #bfb9b0;
        text-align: right;
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.14px;
      }
    }
    .swap-icon {
      position: absolute;
      z-index: 1;
      width: 28px;
      height: 28px;
      top: 32px;
      left: calc(50% - 14px);
      cursor: pointer;
      img {
        width: 28px;
        height: 28px;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 20px;
  }
  .note {
    font-size: 12px;
  }
}

.modal-bank {
  .info-bank {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    .image {
      width: 152px;
      height: 152px;
      justify-content: flex-start;
      align-items: center;
      display: flex;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .info {
      width: 400px;
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      .info-row {
        width: 100%;
        display: flex;
        .label {
          width: 120px;
          color: #262221;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: -0.14px;
        }
        .value {
          color: #262221;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          letter-spacing: -0.24px;
        }
      }
    }
  }
  .note {
    border-radius: 8px;
    background: #ffe071;
    padding: 16px 24px;
    .title {
      color: #262221;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      letter-spacing: -0.24px;
    }
    .dess {
      color: #262221;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.14px;
    }
  }
}

.btn-action {
  border-radius: 8px;
  background: #00a538;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  &.btn-yellow {
    background: #eeac04;
  }
  &.btn-red {
    background: #de3d3d;
  }
  &.btn-blue {
    background: #1767d7;
  }
}

.ant-tabs {
  .ant-tabs-ink-bar {
    background: #8a6145;
  }
  .ant-tabs-nav {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        color: #262221;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        &:hover {
          color: #8a6145;
        }
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #8a6145;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.section {
  border-radius: 8px;
  border: 2px solid #f1f1f1;
  padding: 16px;
  margin-bottom: 16px;
}

.search {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
}

.onRow {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

.ip-allow,
.user-pass {
  .ip-allow-header,
  .user-pass-header {
    overflow: hidden;
    color: #262221;
    text-overflow: ellipsis;
    /* Body 2 (Regular) */
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.14px;
    margin-bottom: 4;
  }
  .ip,
  .value {
    color: #8e8985;
    /* Body 2 (Regular) */
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.14px;
    margin-bottom: 4;
  }
}

.dns-items {
  .dns-type {
    font-weight: bold;
    &.ipv4 {
      color: #1767d7;
    }
    &.ipv6 {
      color: #f5a623;
    }
  }
  .dns-item {
    display: flex;
    align-items: center;
    .label {
      overflow: hidden;
      color: #8e8985;
      text-overflow: ellipsis;
      /* Body 2 (Regular) */
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.14px;
      margin-right: 8px;
    }
    .value {
      overflow: hidden;
      color: #262221;
      text-overflow: ellipsis;
      /* Body 2 (Regular) */
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.14px;
    }
  }
}

.ant-form-item-label label {
  font-weight: bold;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-16 {
  margin-top: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 8px;
}

.secret {
  .anticon {
    margin-left: 8px;
    cursor: pointer;
  }
}

.form-label {
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

.red {
  color: #de3d3d;
}

.green {
  color: #0caf60;
}

.res-items {
  .res-item {
    display: flex;
    .res-label {
      width: 80px;
      text-align: left;
    }
  }
}

.upper-text {
  text-transform: capitalize;
}

.form-two-column {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  & > div {
    padding: 0 5px;
  }
}
